/* Fonts */
@font-face {
  font-family: "SFPro";
  src: url('fonts/SFPro/SF-Pro-Text-Regular.otf');
}
@font-face {
  font-family: "SFCompact";
  src: url('fonts/SFCompact/SF-Compact.ttf');
}
@font-face {
  font-family: "NY";
  src: url('fonts/NewYork/NewYork.ttf');
}
@font-face {
  font-family: "SFMono";
  src: url('fonts/SFMono/SF-Mono.ttf');
}

/* Misc. */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "SFCompact", Arial, Helvetica, sans-serif;
  /* font-family: NY, 'Georgia', 'Times New Roman', Times, serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FDFDFD;
}
code {
  font-family: "SFMono", Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
a {
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  text-decoration: none;
}
h1, h2, h3 {
  font-weight: bold;
  color: #000;
}
h1 { 
  font-size: 2.5rem;
}
h2 {
  font-size: 1.5rem;
}
p {
  color: #000;
  font-size: 1.25rem;
}

@media screen and (min-width: 770px) {
  .vctr {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.icon {
  margin-left: 25%;
  width: 50%;
  margin-bottom: 1rem;
  border-radius: .35rem !important;
}

@media screen and (max-width: 1000px) {
  .hide-if-small {
    display: none;
  }
}

.main-container {
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.floaty-img {
  max-width: 16.5rem;
  float: left;
  margin-right: 2rem;
  margin-bottom: 2rem;
  border-radius: .35rem !important;
}
.img-container {
  display: grid-inline;
  column-gap: 1rem;
}
.grid-img {
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: .35rem !important;
  object-fit: cover;
  width: 15rem;
  height: 15rem;
}

.ctrtxt {
  justify-content: center;
  text-align: center;
}
.xfooter {
  text-align: right;
  font-size: 6pt;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: 1.5rem;
  color: gray;
}